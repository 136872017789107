/*--------- SPLASH ---------*/
.splash_speckle {
  display: none;
  position: absolute;
  background-color: #B1AAAA;
  width: 120px;
  height: 2px;
  z-index: 1000;
}

.splash_gone {
  display: none;
}

.splash {
  /* FOR DEVELOPMENT ONLY */
  /* display: none; */
  z-index: 20000;
  position: fixed;
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  top: 0;
  transition: top 500ms, opacity 400ms;
}

.splash_carousel {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 998;
}

.fullscreen_background_video {
  position: absolute;
  top: 0;
}

#splash-screen {
  cursor: pointer;
  height: 100vh;
  width: 100vw;
  background: #191919;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen_background_video {
    width: 110%;
    top: -10%;
    left: -5%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen_background_video {
    height: 110%;
    left: -10%;
    top: -5%;
  }
}

.splash_overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgb(25,25,25);
  background: radial-gradient(circle, rgba(25,25,25,0.65) 0%, rgba(25,25,25,1) 100%);
  z-index: 999;
}

.splash_content {
  position: absolute;
  z-index: 1000;
  color: white;
  top: 50vh;
  transform: perspective(1px) translateY(-65%);
}

.splash_content img {
  width: 100vw;
  height: 30vh;
  cursor: pointer;
}

.splash_content span {
  text-align: center;
  display: block;
}

.splash_title {
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

.splash_subtitle {
  font-size: 18px;
  font-weight: 300;
}

.splash_bottom_container {
  position: absolute;
  z-index: 1001;
  bottom: 35px;
  width: 90%;
  right: 5%;
}

.progress_container {
  display: inline-block;
  position: relative;
  width: calc(100% - var(--discover_btn_width));
}

.progress_bar {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 0%;
  height: 4px;
  border-radius: 2px;
  padding-bottom: 4px; 
}

.discover_btn {
  display: block;
  margin: auto;
  font-size: 15px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 50px;
  padding: 15px 50px;
  transition-duration: 0.15s;
}

.discover_btn:hover {
  transition-duration: 0.3s;
  background-color: white;
  color: var(--overlay);
  cursor: pointer;
}

/* MEDIA QUERY FOR LARGE DESKTOP */
@media only screen and (max-width:1200px){

}

/* MEDIA QUERY FOR LARGE TABLETS AND SMALL DESKTOP */
@media only screen and (max-width:992px){

}

/* MEDIA QUERY FOR TABLETS */
@media only screen and (max-width:768px){

}

/* MEDIA QUERY FOR MOBILE */
@media only screen and (max-width:640px){


  .fullscreen_background_video {
    left: -50%;
  }

  .splash_carousel {
    background-position: 60% center;
  }

  .splash_content {
    display: flex;
    flex-wrap: wrap;
  }

  .splash_content img {
    display: block;
    order: 2;
    width: 95vw;
    height: 30vh;
    margin: auto;
  }

  #splash-title-container {
    order: 1;
    width: 90%;
    margin: auto;
  }

  .splash_title {
    align-content: center;
    font-size: 30px;
    font-weight: 700;
  }

  .splash_subtitle {
    font-size: 14px;
    font-weight: 300;
  }
  
  .progress_container {
    display: none;
  }

  .splash_bottom_container {
    bottom: 20vh;
  }

  .discover_btn {
    display: block;
    margin: auto;
  }
}

@media only screen and (max-width:375px) {
  
  .fullscreen_background_video {
    left: -75%;
  }
}